import { Sender } from '@wix/ambassador-innovation-widget-v1-message/types';

export const senderIsAssistant = (sender?: Sender) =>
  sender === Sender.ASSISTANT;
export const senderIsVisitor = (sender?: Sender) =>
  sender === Sender.UOU || sender === Sender.SITE_VISITOR;
export const senderIsOwner = (sender?: Sender) => sender === Sender.USER;
export const senderIsSystem = (sender?: Sender) => sender === Sender.SYSTEM;

export const senderIsAnswer = (sender?: Sender) =>
  senderIsAssistant(sender) || senderIsOwner(sender);
